var initSlick = function() {

  var aboutBuilding = $('.o-slider-main').slick({
    arrows: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 10000,
    slidesToShow: 1
  });

  var apartmentCarousel = $('.c-apartment-carousel').slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1
  });

  var ourBuildings = $('.c-objects-slider__carousel').slick({
    centerMode: true,
    centerPadding: '-120px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: '-120px',
          slidesToShow: 1
        }
      }
    ]
  });

};
