$(function() {

    $('.navbar-toggle').on('click', function() {
        $('.mobile-nav-overlay').addClass('mobile-nav-overlay--visible');

        document.ontouchmove = function(e){ e.preventDefault(); };
    });

    $('.mobile-nav-overlay__close').on('click', function() {
        $('.mobile-nav-overlay').removeClass('mobile-nav-overlay--visible');

        document.ontouchmove = function(e){ return true; };
    });

});
