var apartmentList = function() {
  // init state
  $('.c-apartments-list__right').children('.c-apartments-list-item:not(' + '.c-apartments-list-item--' + 'Dvosoban' + ')').hide();
  $('.c-apartments-list__right').children('.c-apartments-list-item' + '.c-apartments-list-item--'+ 'Dvosoban').show();

  // change state on click
  $('.c-apartments-list__type-select a').on('click', function(){

      $('.c-apartments-list__type-select a').removeClass('active');
      $(this).addClass('active');

      var categoryName = $(this).data('nav-filter');
      $('.c-apartments-list__right').children('.c-apartments-list-item:not(' + '.c-apartments-list-item--' + categoryName + ')').hide();
      $('.c-apartments-list__right').children('.c-apartments-list-item' + '.c-apartments-list-item--'+ categoryName).show();

      return false;
  });
};


var apartmentList2 = function() {
  // init state
  $('.c-apartments2-list-container').children('.c-apartments2-list-item').hide();

  // change state on click
  $('.c-apartments-list-item a').on('click', function(){

      $('.c-apartments-list-container').hide();
      $('.c-apartments2-list-container').show();

      var categoryName = $(this).data('nav-filter');
      $('.c-apartments2-list-container').children('.c-apartments2-list-item' + '.c-apartments2-list-item--'+ categoryName).show();

      return false;
  });
};

var apartmentList2Back = function() {
  $('.c-apartments2-list-item__back').on('click', function(){

    $('.c-apartments2-list-container').children('.c-apartments2-list-item').hide();
    $('.c-apartments2-list-container').hide();
    $('.c-apartments-list-container').show();

  });
};

var apartmentListInit = function() {
  apartmentList();
  apartmentList2();
  apartmentList2Back();
};
