var initMap = function() {

    // var center = {lat: 45.309992, lng: 19.836954}; // zoom 15
    var center = {lat: 45.245482, lng: 19.837268}; // zoom 14
    var olgePetrov = {lat: 45.244766, lng: 19.819610}; // zoom 14
    var caraDusana = {lat: 45.245264, lng: 19.825068}; // zoom 14
    var markerPos = center;
    var markerPos2 = olgePetrov;
    var markerPos3 = caraDusana;

    // function detectmob() {
    //
    //   var centerDesktop = {lat: 45.246516, lng: 19.837636}; // zoom 14
    //   var centerMobile = {lat: 45.250516, lng: 19.837636};
    //
    //   if(window.innerWidth <= 800 && window.innerHeight <= 600) {
    //     return center = centerMobile;
    //   }
    //   else {
    //     return center = centerDesktop;
    //   }
    // };
    //
    // detectmob();

    var map = new google.maps.Map($('.c-contact-us__map')[0], {
        center: center,
        zoom: 15,
        scrollwheel: false,
        draggable: false,
        scrollable: false
    });

    var marker = new google.maps.Marker({
        position: markerPos,
        map: map
    });

    // var marker2 = new google.maps.Marker({
    //     position: markerPos2,
    //     map: map
    // });

    var marker3 = new google.maps.Marker({
        position: markerPos3,
        map: map
    });

    var infowindow = new google.maps.InfoWindow({
        content: '<b>SlavijaCoop DOO</b><br>Lasla Gala 23, Novi Sad<br>021/ 489 - 9999'
    });

    // var infowindow2 = new google.maps.InfoWindow({
    //     content: '<b>SLAVIJA Residence</b><br>Useljeno'
    // });

    var infowindow3 = new google.maps.InfoWindow({
        content: '<a href="/cara-dusana-35"><b>Slavija Residence Luxury</b></a><br>Objekat u izgradnji'
    });

    infowindow.open(map, marker);
    // infowindow2.open(map, marker2);
    infowindow3.open(map, marker3);
};
