$(document).ready(function(){

    // $('#header__icon').click(function(e){
    //     e.preventDefault();
    //     $('body').toggleClass('with--sidebar');
    // });
    //
    // $('#site-cache').click(function(e){
    //     $('body').removeClass('with--sidebar');
    // });
    //
    // $('#mobile-nav li a').click(function(e){
    //     $('body').removeClass('with--sidebar');
    // });
    //
    // $('.tooltip').tooltipster({
    //   theme: 'tooltipster-custom',
    //   arrow: false,
    //   delay: 200,
    //   side: ['left']
    // });

  $(window).scroll(function() {
    var height = $(window).scrollTop();

    if(height  > 550) {
      $('.c-choose-floor__img').addClass('animate-lights');
    }
  });

  initMap();
  apartmentListInit();
  initSlick();
  // pageAccelerator();
});

plyr.setup();
