(function( $ ) {
	$.fn.clipPath = function() {

		this.filter('[data-clipPath-prvi]').each(function(i) {

			//get IMG attributes
			var maskPath = [$(this).attr('data-clipPath-lokali'), $(this).attr('data-clipPath-prizemlje'), $(this).attr('data-clipPath-prvi'), $(this).attr('data-clipPath-drugi'), $(this).attr('data-clipPath-treci'), $(this).attr('data-clipPath-cetvrti'), $(this).attr('data-clipPath-peti'), $(this).attr('data-clipPath-sesti'), $(this).attr('data-clipPath-sedmi'), $(this).attr('data-clipPath-potkrovlje')];
			var maskHref = [$(this).attr('data-href-lokali'), $(this).attr('data-href-prizemlje'), $(this).attr('data-href-prvi'), $(this).attr('data-href-drugi'), $(this).attr('data-href-treci'), $(this).attr('data-href-cetvrti'), $(this).attr('data-href-peti'), $(this).attr('data-href-sesti'), $(this).attr('data-href-sedmi'), $(this).attr('data-href-potkrovlje')];

			var maskSrc = $(this).attr('src');
			var maskWidth = $(this).attr('width');
			var maskHeight = $(this).attr('height');
			var maskAlt = $(this).attr('alt');
			var maskTitle = $(this).attr('title');
			var uniqueID = i;

			var defs = [];
			var clipPaths = [];

			for (n = 0; n < maskPath.length; n++) {
				defs.push(
					'<clipPath id="maskID' + uniqueID + n + '">'
						+ '<polygon points="'
						+maskPath[n]+'"/>'
					+'</clipPath>'
				);

				clipPaths.push (
					'<image clip-path="url(#maskID'
					+uniqueID + n + ')"  onclick="location.href=' + "'"
					+maskHref[n]+ "'" + '" width="'
					+'100%'+'" height="'
					+'100%'+'" xlink:href="'
					+maskSrc+'">'
					+ '</image>'
				);
			}

			// build SVG from our IMG attributes & path coords.
			var svg = $('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="svgMask" width="'
				+'100%'+'" height="'
				+'100%'+'" viewBox="0 0 '
				+maskWidth+' '
				+maskHeight+'">'
				+'<defs>'
				+defs.join("")
				+'</defs>'
				+'<title>'
				+maskTitle+'</title><desc>'
				+maskAlt+'</desc>'
				+clipPaths.join("")
				+'/></svg>');

			//swap original IMG with SVG
			$(this).replaceWith(svg);

			//clean up
			delete maskPath, maskSrc, maskWidth, maskHeight, maskAlt, maskTitle, uniqueID, svg;

		});

		return this;

	};

}( jQuery ));

//USAGE:
$('img').clipPath();
