$(function() {

    $('.contact-form input').on('change', function() {
        console.log(2);
        $('.input--error').removeClass('input--error');
    });

    $('#contactus').on('submit', function(e) {
        console.log(1);

        $('.input--error').removeClass('input--error');

        var err = false;

        if (!$('.contact-form__input--name').val().length) {
            $('.contact-form__input--name').addClass('input--error');
            err = true;
        }

        if (!$('.contact-form__input--email').val().length) {
            $('.contact-form__input--email').addClass('input--error');
            err = true;
        }

        if (!$('.contact-form__input--subject').val().length) {
            $('.contact-form__input--subject').addClass('input--error');
            err = true;
        }

        if (!$('.contact-form__input--question').val().length) {
            $('.contact-form__input--question').addClass('input--error');
            err = true;
        }

        if (err) {
            e.preventDefault();
        }

    });
});
